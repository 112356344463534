import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import style from './SidePanel.module.css'

import { PlaceBidButton } from './PlaceBidButton/PlaceBidButton'
import { hideSidePanelObserver, reRenderSidePanelObserver, showSidePanelObserver } from '../../state/observerStore'
import { sleep } from '../../utils/pure'
import Countdown from 'react-countdown'
import { ReactComponent as SvgSymbolHelp } from './symbol-help.svg'

import { getFruitsMetadataFromLineIndex } from '../../state/fruitsMetadataStore'
import { useUserStore } from '../../state/userStore'
import { DappConnector } from './DappConnector/DappConnector'
import { toast } from 'react-hot-toast'
import { constVoid, pipe } from 'fp-ts/lib/function'
import { minPrice, useKagamiStore } from '../../state/kagamiStore'
import { formatAddr, fromWei, runStoreTx } from '../../utils/web3'
import { maxSupply, PROVIDER_DOWN_MESSAGE, useParallelAuctionState } from '../../state/autoAuctionStore'
import { useWinnerState } from '../../state/winnerStore'

export const SidePanel: React.FC = () => {
    const lineIndex = useParallelAuctionState((s) => s.currentLineIndex)
    const id = lineIndex + 1
    const metadata = getFruitsMetadataFromLineIndex(lineIndex)
    const line = useParallelAuctionState((state) => state.getCurrentSelectedLine)()
    const lineFinished = O.isSome(line) && line.value.head > maxSupply
    reRenderSidePanelObserver((s) => s.observer) // Subscription

    const currentBid = useParallelAuctionState((s) => s.getFormattedCurrentBid)(lineIndex)
    const endTime = useParallelAuctionState((s) => s.getEndTime)(lineIndex)
    const currentWinner = useParallelAuctionState((s) => s.getFormattedCurrentWinner)(lineIndex)

    // NOTE That the side panel animation depends on other component
    // interactions, thats why we use those following hooks and observers.
    const sidePanelRef = useRef<HTMLDivElement>(null)
    const onChangeHidePanel = hideSidePanelObserver((s) => s.observer)
    const onChangeShowPanel = showSidePanelObserver((s) => s.observer)

    const userConnected = useUserStore((s) => s.userConnected)
    const connection = useUserStore((s) => s.connectUser)

    useEffect(() => {
        if (!sidePanelRef.current) return
        sidePanelRef.current.setAttribute('data-state-visibility', 'closed')
    }, [onChangeHidePanel])

    useEffect(() => {
        if (!sidePanelRef.current) return
        sidePanelRef.current.setAttribute('data-state-visibility', 'open')
    }, [onChangeShowPanel])

    const hideSidePanel = hideSidePanelObserver((s) => s.notifyObservers)

    useEffect(() => {
        if (window.innerWidth < 960) hideSidePanel()
    }, [])

    const handleHide = async () => {
        hideSidePanel()
        await sleep(0.25)
    }

    // TAB NAVIGATION ------------------------- //
    const [activeTab, setActiveTab] = useState<'bid' | 'redeem'>('bid')

    const handleTabClick = (tabName: 'bid' | 'redeem') => {
        setActiveTab(tabName)
    }

    // KAGAMI LANGUAGE SWITCH ---------------- //
    const [infoBoxLang, setInfoBoxLang] = useState<'en' | 'jp'>('en')

    const handleInfoBoxLangSwitch = (lang: 'en' | 'jp') => {
        setInfoBoxLang(lang)
    }

    // HELP OVERLAY -------------------------- //
    const [isHelpOverlayActive, setIsHelpOverlayActive] = useState<boolean>(false)
    const [activeOverlay, setActiveOverlay] = useState<'en' | 'jp' | null>(null)

    const handleHoverHelp = (lang: 'en' | 'jp') => {
        setIsHelpOverlayActive(true)
        setActiveOverlay(lang)
    }

    const handleUnhoverHelp = () => {
        setIsHelpOverlayActive(false)
        setActiveOverlay(null)
    }
    // KAGAMI ACADEMY STATE ---------------------------------- //    
    const kagamiData = useKagamiStore(s => s.kagamiData)
    const loadKagamiData = useKagamiStore((s) => s.loadKagamiData)
    const userAddress = useUserStore((s) => s.userAddress)
    useEffect(() => { loadKagamiData() }, [userAddress])

    // $KAGAMI WITHDRAWING/BALANCES -------------------------- //
    const withdrawKagami = useKagamiStore((s) => s.withdrawKagami)
    const formattedKagamiBalance = pipe(kagamiData, O.map(d => d.formattedBalances))

    const onWithdrawClick = async () => {
        if (!userConnected) connection()
        else pipe(
            await runStoreTx(withdrawKagami, constVoid()),
            O.map(_ => loadKagamiData())
        )
    }

    const withdrawButton = () =>
        <button id={style['action-withdraw']} onClick={onWithdrawClick}>
            <div>{userConnected ? 'WITHDRAW' : 'CONNECT WALLET'}</div>
        </button>

    const getKagamiBalance = () => {
        if (userConnected && O.isSome(formattedKagamiBalance))
            return formattedKagamiBalance.value
        else return 'Connect Wallet'
    }

    // KAGAMI ACADEMY MINTING ------------------------------------------- //
    const ownedKagami = pipe(kagamiData, O.map(d => d.ownedBalance))
    const kagamiAllowance = pipe(kagamiData, O.map(d => d.kagamiAllowance))
    const approveKagamiAcademy = useKagamiStore(s => s.approveKagamiAcademy)
    const maxMintKagamiAcademy = useKagamiStore(s => s.maxMintKagamiAcademy)

    const amountToMint = pipe(
        useKagamiStore(s => s.getAmountToMint)(),
        O.map(String),
        O.map(n => `MINT ${n}`),
    )

    const approvePipeline = async () => pipe(
        await runStoreTx(approveKagamiAcademy, constVoid()),
        O.map(_ => loadKagamiData())
    )

    const maxMintPipeline = async () => pipe(
        await runStoreTx(maxMintKagamiAcademy, constVoid()),
        O.map(_ => loadKagamiData())
    )

    const getOnMintState = (): [MouseEventHandler<HTMLButtonElement>, string] => {
        if (!userConnected || O.isNone(ownedKagami) || O.isNone(kagamiAllowance))
            return [connection, 'CONNECT WALLET']
        if (ownedKagami.value < minPrice)
            return [() => toast.error('Insufficient $KAGAMI'), 'INSUFFICIENT BALANCE']
        if (kagamiAllowance.value < minPrice)
            return [approvePipeline, 'APPROVE']
        if (O.isNone(amountToMint))
            return [() => toast.error('Something went wrong'), 'ERROR']

        return [maxMintPipeline, amountToMint.value]
    }

    const [mintButtonHook, mintButtonText] = getOnMintState()

    const updateWinnersState = useWinnerState(s => s.updateState)
    useEffect(() => {
        updateWinnersState() 
    }, [lineFinished])

    const lineWinners = pipe(
        useWinnerState(s => s.lineWinners),
        A.map(pipe(O.flatMap(w => formatAddr(w, 11))))
    )
    const winningBids = pipe(
        useWinnerState(s => s.winningBid),
        A.map(pipe(O.map(p => `Ξ${fromWei(p)}`)))
    )

    return (
        <div id={style['side-panel']} ref={sidePanelRef}>
            <div id={style['panel-action-container']}>
                <div className={style['row']}>
                    <DappConnector />

                    <button id={style['hide-button']} onClick={handleHide}>
                        {' '}
                        <span>Hide -&gt;</span>
                    </button>
                </div>

                <div className={style['row']}>
                    <div id={style['tab-container']}>
                        <div
                            id={style['tab-bid']}
                            data-state={activeTab === 'bid' ? 'active' : ''}
                            onClick={() => handleTabClick('bid')}
                        >
                            <span>Bid</span>
                        </div>
                        <div
                            id={style['tab-redeem']}
                            data-state={activeTab === 'redeem' ? 'active' : ''}
                            onClick={() => handleTabClick('redeem')}
                        >
                            <span>Redeem</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* SECTION: BID VIEW */}
            <section id={style['view-bid']} data-state={activeTab === 'bid' ? 'active' : ''}>
                {/* Token Details */}
                <div className={style['delin']}></div>

                <div id={style['selected-token-details-container']} className={style['details-container']}>
                    <div className={style['item']}>
                        <span>FRUiTS MiLADY [{metadata.date}] -&gt;</span>
                        <span>{metadata.name}</span>
                    </div>

                    <div className={style['item']}>
                        <span>Fashion tips -&gt;</span>
                        <span>{metadata.fashionTip}</span>
                    </div>

                    <div className={style['item']}>
                        <span>Favorite music -&gt;</span>
                        <span>{metadata.favoriteMusic}</span>
                    </div>

                    <div className={style['item']}>
                        <span>Occupation -&gt;</span>
                        <span>{metadata.occupation}</span>
                    </div>

                    <div className={style['item']}>
                        <span>ID -&gt;</span>
                        <span>{id}</span>
                    </div>
                </div>

                <div className={style['delin']}></div>

                {/* Bid Details */}
                <div
                    id={style['focus-token-auction-details-container']}
                    className={style['details-container']}
                    style={{ display: 'flex' }}
                >
                    <div className={style['item']}>
                        <span>{!lineFinished ? 'Current bid / 現在 ->' : 'Winning bid / 落札価格 ->'}</span>
                        <span>
                            {!lineFinished ? currentBid : O.getOrElse(() => 'Loading')(winningBids[lineIndex])}
                        </span>
                    </div>

                    <div className={style['item']} style={{ display: !lineFinished ? 'flex' : 'none'}}>
                        <span>Ends in / 入札締切まで -&gt;</span>
                        <span>
                            {O.isSome(endTime) ? <Countdown date={endTime.value * 1000} /> : PROVIDER_DOWN_MESSAGE()}
                        </span>
                    </div>

                    <div className={style['item']}>
                        <span>{!lineFinished ? 'Last bid by / 最高入札者 -&gt;' : 'Winner / 落札者 ->'}</span>
                        <span>
                            {!lineFinished ? currentWinner : O.getOrElse(() => 'Loading')(lineWinners[lineIndex])}
                        </span>
                    </div>
                </div>

                <PlaceBidButton enabled={!lineFinished} />
            </section>

            <section id={style['view-redeem']} data-state={activeTab === 'redeem' ? 'active' : ''}>
                <div className={style['delin']}></div>

                <div id={style['balance-container']}>
                    <div className={style['label']}>$KAGAMI</div>
                    <div className={style['value']}>{getKagamiBalance()}</div>
                </div>

                <div className={style['info-box']} id={style['info-box-what-is-kagami']}>
                    <div className={style['title-row']}>
                        {infoBoxLang === 'en' && <h3>What is $KAGAMI?</h3>}
                        {infoBoxLang === 'jp' && <h3>$KAGAMIとは？</h3>}
                        <div id={style['info-box-what-is-kagami-lang-switch']}>
                            <span
                                data-state={infoBoxLang === 'en' ? 'active' : ''}
                                onClick={() => handleInfoBoxLangSwitch('en')}
                            >
                                EN
                            </span>
                            <span
                                data-state={infoBoxLang === 'jp' ? 'active' : ''}
                                onClick={() => handleInfoBoxLangSwitch('jp')}
                            >
                                JP
                            </span>
                        </div>
                    </div>
                    {infoBoxLang === 'en' && (
                        <span>
                            {' '}
                            Each time you place a FRUiTS x REMiLiA bid you'll receive 2 $KAGAMI. Once withdrawn, each
                            $KAGAMI can be used to mint your very own Kagami Academy NFT. Each mint costs 2 $KAGAMI.
                        </span>
                    )}
                    {infoBoxLang === 'jp' && (
                        <span>
                            FRUiTSxREMiLiAオークションに入札する度に、$KAGAMIを2つ受け取れます。
                            引き出した$KAGAMIを2つ使って自分のKAGAMI ACADEMY NFTを作成できます
                        </span>
                    )}
                    {withdrawButton()}
                </div>

                <div className={style['delin']}></div>

                <div id={style['nft-container-kagami']}>
                    <button
                        id={style['action-help-kagami-nft-en']}
                        className={style['action-help-kagami-nft']}
                        onMouseEnter={() => handleHoverHelp('en')}
                        onMouseLeave={handleUnhoverHelp}
                    >
                        <span>EN</span>
                        <SvgSymbolHelp />
                    </button>

                    <button
                        id={style['action-help-kagami-nft-jp']}
                        className={style['action-help-kagami-nft']}
                        onMouseEnter={() => handleHoverHelp('jp')}
                        onMouseLeave={handleUnhoverHelp}
                    >
                        <span>JP</span>
                        <SvgSymbolHelp />
                    </button>

                    <div
                        id={style['overlay-help-kagami-nft-en']}
                        className={style['overlay-help-kagami-nft']}
                        data-state={isHelpOverlayActive && activeOverlay === 'en' ? 'active' : ''}
                    >
                        <div className={style['about-container']}>
                            <span>
                                Kagami Academy is a 33 x 44 pixel NFT collection featuring the 8 REMiLiA-kei best
                                friends who form REMiLiA High's Afterschool Dessert Club. Meet Cream, Cocoa, Butter,
                                Rose, Peach, Vanilla, Lavender and Slime.
                            </span>
                        </div>
                    </div>

                    <div
                        id={style['overlay-help-kagami-nft-jp']}
                        className={style['overlay-help-kagami-nft']}
                        data-state={isHelpOverlayActive && activeOverlay === 'jp' ? 'active' : ''}
                    >
                        <div className={style['about-container']}>
                            <span>
                                KAGAMI ACADEMYは、REMiLiA
                                High放課後デザート部のメンバー、REMiLiA系の8人ベストフレンドが登場する33 x
                                44ピクセルアートNFTコレクションです。Cream, Cocoa, Butter, Rose, Peach, Vanilla,
                                Lavender, そしてSlimeに会ってみましょう。
                            </span>
                        </div>
                    </div>
                </div>
                <button id={style['action-mint']} onClick={mintButtonHook}>
                    {mintButtonText}
                </button>
            </section>
        </div>
    )
}
