import React, { useState } from 'react'
import { useParallelAuctionState } from '../../state/autoAuctionStore'
import { reRenderSidePanelObserver, showSidePanelObserver } from '../../state/observerStore'
import style from './AuctionGallery.module.css'
import imgCornerStarA from './star-a.webp'
import imgCornerStarB from './star-b.webp'
import imgCornerStarC from './star-c.webp'
import imgCornerStarD from './star-d.webp'
import imgNameplate1 from './nameplate-1.webp'
import imgNameplate2 from './nameplate-2.webp'
import imgNameplate3 from './nameplate-3.webp'
import imgNameplate4 from './nameplate-4.webp'
import imgNameplate5 from './nameplate-5.webp'
import imgNameplate6 from './nameplate-6.webp'
import imgFruitsRef1 from './fruits-ref-1.webp'
import imgFruitsRef2 from './fruits-ref-2.webp'
import imgFruitsRef3 from './fruits-ref-3.webp'
import imgFruitsRef4 from './fruits-ref-4.webp'
import imgFruitsRef5 from './fruits-ref-5.webp'
import imgFruitsRef6 from './fruits-ref-6.webp'

export const AuctionGallery = () => {
	const setLineIndex = useParallelAuctionState((state) => state.setCurrentSelectedIndex)
	const reRenderSidePanel = reRenderSidePanelObserver((s) => s.notifyObservers)
	const updateLine = useParallelAuctionState((s) => s.updateLine)

	// NFT ASSETS STATE ----------------------- //
	const [selectedToken, setSelectedToken] = useState(1)
	const [nameplate, setNameplate] = useState(imgNameplate1)
	const fruitsRefsData = [imgFruitsRef1, imgFruitsRef2, imgFruitsRef3, imgFruitsRef4, imgFruitsRef5, imgFruitsRef6]

	const iframeSrcsData = [
		'3d-model-1.html',
		'3d-model-2.html',
		'3d-model-3.html',
		'3d-model-4.html',
		'3d-model-5.html',
		'3d-model-6.html'
	]
	const [iframeSrc, setIframeSrc] = useState(iframeSrcsData[0])

	const updateSelectedTokenAssets = (
		id: number,
		setSelectedToken: Function,
		setNameplate: Function,
		setIframeSrc: Function
	) => {
		setSelectedToken(id + 1)
		setIframeSrc(iframeSrcsData[id])
		switch (id + 1) {
			case 1:
				setNameplate(imgNameplate1)
				break
			case 2:
				setNameplate(imgNameplate2)
				break
			case 3:
				setNameplate(imgNameplate3)
				break
			case 4:
				setNameplate(imgNameplate4)
				break
			case 5:
				setNameplate(imgNameplate5)
				break
			case 6:
				setNameplate(imgNameplate6)
				break
		}
	}

	// ---------------------------------------- //

	const changeTab = (id: number) => async () => {
		setLineIndex(id)
		await updateLine(id)
		reRenderSidePanel()
		updateSelectedTokenAssets(id, setSelectedToken, setNameplate, setIframeSrc)
	}

	const unhideSidePanel = showSidePanelObserver((s) => s.notifyObservers)

	return (
		<div id={style['auction-gallery-container']}>
			<div id={style['nft-aux-container']}>
				<div id={style['nft-about-container']}>
					<img className={style['nameplate']} src={nameplate} alt="" />
				</div>
				<div id={style['fruits-reference-container']}>
					{fruitsRefsData.map((item, index) => {
						return (
							<div
								key={index}
								className={style['fruits-reference-img']}
								style={{ backgroundImage: `url(${item})` }}
								data-state={selectedToken === index + 1 ? 'active' : undefined}
							/>
						)
					})}
				</div>
			</div>
			<div id={style['nft-gallery-container']}>
				<div id={style['nft-container']}>
					<img src={imgCornerStarA} alt="" id={style['corner-star-a']} />
					<img src={imgCornerStarB} alt="" id={style['corner-star-b']} />
					<img src={imgCornerStarC} alt="" id={style['corner-star-c']} />
					<img src={imgCornerStarD} alt="" id={style['corner-star-d']} />

					<iframe title="FRUiTS x REMiLiA NFT" src={iframeSrc}></iframe>

					<div id={style['action-bid']} onClick={unhideSidePanel}>
						<span>BID</span>
					</div>
				</div>
				<nav id={style['nav']}>
					<div id={style['nft-anchor-1']} onClick={changeTab(0)}></div>
					<div id={style['nft-anchor-2']} onClick={changeTab(1)}></div>
					<div id={style['nft-anchor-3']} onClick={changeTab(2)}></div>
					<div id={style['nft-anchor-4']} onClick={changeTab(3)}></div>
					<div id={style['nft-anchor-5']} onClick={changeTab(4)}></div>
					<div id={style['nft-anchor-6']} onClick={changeTab(5)}></div>
				</nav>
			</div>
		</div>
	)
}
