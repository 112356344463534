import { useEffect } from 'react'
import './theme/design-tokens/_STYLE_TOKENS_.css'
import './theme/root.css'
import { useParallelAuctionState } from './state/autoAuctionStore'
import { useUserStore } from './state/userStore'
import { AuctionPage } from './components/AuctionPage/AuctionPage'
import { SidePanel } from './components/SidePanel/SidePanel'

const BORDER_GRID_COUNT_X = 12
const BORDER_GRID_COUNT_Y = 12

function App() {
	const config = useParallelAuctionState((s) => s.setAuctionData)
	const auctionData = useParallelAuctionState((s) => s.auctionData)
	const userConnected = useUserStore((s) => s.userConnected)

    // TODO This all should be decoupled into a config file and
    // evaluated from a store. Or maybe it would be better to just
    // query it from the contract with a default provider.
	useEffect(() => {
		config('FruitsMilady', 'Not Needed')
	}, [userConnected, auctionData, config])

	// Border grid injector
	const injectBorderGrids = (count: number): JSX.Element[] =>
		Array.from({ length: count }).map((_, i) => (
			<div key={i} className="border-grid-item">
				<span>.</span>
			</div>
		))

	return (
		<div className="App">
			<div id="border-container-top">{injectBorderGrids(BORDER_GRID_COUNT_X)}</div>

			<div id="app-body">
				<div id="border-container-left">{injectBorderGrids(BORDER_GRID_COUNT_Y)}</div>
				<AuctionPage />
				<div id="border-container-right">{injectBorderGrids(BORDER_GRID_COUNT_Y)}</div>
			</div>

			<div id="border-container-bottom">{injectBorderGrids(BORDER_GRID_COUNT_X)}</div>

			<SidePanel />
		</div>
	)
}

export default App
