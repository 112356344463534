import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { BigNumberish, ContractTransactionResponse, ethers } from "ethers"
import { constVoid, pipe } from 'fp-ts/lib/function'
import { toast } from 'react-hot-toast'

export const formatAddr = (
    addr: string[42], digitsToShow: number = 6
): O.Option<string> => {
    if (!ethers.isAddress(addr)) return O.none
    const n = Math.floor(digitsToShow / 2)
    const m = digitsToShow % 2 !== 0 ? n + 1 : n
    return O.of(`${addr.substr(0, n + 2)}...${addr.substr(m * (-1))}`)
}

/**
 * @dev Forces a `fromatAddr`
 */
export const fformatAddr = (
    addr: string[42], digitsToShow: number = 6
): string => {
    const optF = formatAddr(addr, digitsToShow)
    if (O.isSome(optF)) return optF.value
    else return ''
}

export const formatOptAddr = (
    addr: O.Option<string[42]>, digitToShow: number = 6
): string => pipe(
    addr,
    O.chain(addr => formatAddr(addr, digitToShow)),
    O.getOrElse(() => '')
)

export const fromWei = (x: BigNumberish | bigint) => ethers.formatUnits(x, 'ether')
export const toWei = (x: number) => ethers.parseEther(x.toString())

export const ZERO_ADDR = `0x${'0'.repeat(40)}`

const transactionSuccesfulToastDuration = 8000

/**
 * @dev Tries to run a contract store transaction `f` with inputs `input`.
 * @returns an early `O.none` if the transaction gets cancelled.
 */
export async function runStoreTx<T>(
    f: (x: T) => Promise<O.Option<ContractTransactionResponse>>,
    input: T
): Promise<O.Option<void>> {
    const toastAwaiting = toast.loading('Awaiting signature...')

    const tx = await f(input)

    toast.dismiss(toastAwaiting)

    if (O.isNone(tx)) {
        toast.error('Transaction error')
        return O.none
    }

    const toastTxPending = toast.loading('Transaction pending...')

    const receipt = await tx.value.wait()

    toast.dismiss(toastTxPending)
    toast.success('Transaction succesful!', {
        duration: transactionSuccesfulToastDuration
    })

    console.log({ ...receipt })
    return O.some(constVoid())
}
