import { constVoid, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import * as TO from 'fp-ts/TaskOption'
import { TO2 } from '../utils/pure'
import { create } from 'zustand'
import { IArchetype, IArchetype__factory, IRewardToken, IRewardToken__factory, ISharesHolder, ISharesHolder__factory } from '../types'
import { useUserStore } from './userStore'
import { fromWei, toWei } from '../utils/web3'
import { ContractTransactionResponse, ethers } from 'ethers'
import { auctionAddress } from './autoAuctionStore'

export const minPrice = ethers.parseUnits("2", "ether") // FIXME HARDCODE
const rewardTokenAddress = process.env.REACT_APP_REWARD_TOKEN!
const kagamiAcademyAddr = process.env.REACT_APP_KAGAMI_ACADEMY!

type KagamiData = {
    readonly auctionSharesHolder: ISharesHolder,
    readonly rewardToken: IRewardToken,
    readonly kagamiAcademyContract: IArchetype,
    readonly formattedBalances: string,
    // Amount of $KAGAMI that the KagamiAcademy NFT Token is allowed to spend.
    readonly kagamiAllowance: bigint,
    readonly ownedBalance: bigint
}

type LoadingDataFailureReason =
    | 'user signer not present'
    | 'user address not present'

export type KagamiStoreState = {
    kagamiData: O.Option<KagamiData>,
    loadKagamiData: () => Promise<E.Either<LoadingDataFailureReason, KagamiData>>,
    withdrawKagami: () => Promise<O.Option<ContractTransactionResponse>>,
    approveKagamiAcademy: () => Promise<O.Option<ContractTransactionResponse>>,
    maxMintKagamiAcademy: () => Promise<O.Option<ContractTransactionResponse>>,
    getAmountToMint: () => O.Option<number>
}

export const useKagamiStore = create<KagamiStoreState>((set, get) => { return {

    kagamiData: O.none,

    loadKagamiData: async () => {
        const maybeUserSigner = useUserStore.getState().userSigner
        // If the user is not connected, theres not really any reason to
        // try to query anything.
        if (O.isNone(maybeUserSigner)) return E.left('user signer not present')

        const maybeUserAddress = useUserStore.getState().userAddress
        if (O.isNone(maybeUserAddress)) return E.left('user address not present')

        // Otherwise, if the user IS present, we can query all of `kagamiData`.
        const signer = maybeUserSigner.value
        const userAddress = maybeUserAddress.value

        const auctionSharesHolder = ISharesHolder__factory.connect(auctionAddress, signer)
        const rewardToken = IRewardToken__factory.connect(rewardTokenAddress, signer)

        const kagamiAcademyContract = IArchetype__factory.connect(kagamiAcademyAddr, signer)

        const ownedBalance = await rewardToken.balanceOf(userAddress)
        const toClaim = await auctionSharesHolder.getTokenShares(userAddress).then(fromWei)

        const formatedOwned = parseFloat(fromWei(ownedBalance)).toFixed(2)
        const formatedToClaim = parseFloat(toClaim).toFixed(2)
        const formattedBalances = `${formatedOwned} owned / ${formatedToClaim} to claim`

        const kagamiAllowance = await rewardToken.allowance(userAddress, kagamiAcademyAddr)

        const kagamiData: KagamiData = {
            rewardToken, auctionSharesHolder, formattedBalances,
            ownedBalance, kagamiAllowance, kagamiAcademyContract 
        }
        set({ kagamiData: O.of(kagamiData) })
        return E.right(kagamiData)
    },

	withdrawKagami: async () => pipe(
        get().kagamiData,
        O.map(d => d.rewardToken),
        TO.fromOption,
        TO2.flatTry(rewardToken => rewardToken.claimShares())
    )(),

    approveKagamiAcademy: () => pipe(
        get().kagamiData,
        O.map(d => d.rewardToken),
        TO.fromOption,
        TO2.flatTry(rewardToken => rewardToken.approve(kagamiAcademyAddr, toWei(10000)))
    )(),

    maxMintKagamiAcademy: async () => pipe(
        O.Do,
        O.bind('kagamiData', () => get().kagamiData),
        O.bind('amountToMint', get().getAmountToMint),
        TO.fromOption,
        TO2.flatTry(({kagamiData, amountToMint}) => kagamiData.kagamiAcademyContract.mint(
            { key: ethers.ZeroHash, proof:[] },
            amountToMint,
            ethers.ZeroAddress,
            '0x'
        ))
    )(),

    getAmountToMint: () => pipe(
        get().kagamiData,
        O.map(({ ownedBalance }) => ownedBalance/minPrice),
        O.map(Number)
    )

}})
