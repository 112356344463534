
type AuctionId = (1 | 2 | 3 | 4 | 5 | 6)

export const getFruitsMetadata = (tokenId: AuctionId) => {
    switch (tokenId) {
        case 1: return {
            date: "05/1999",
            name: "Hiromyon",
            fashionTip: "Lazy",
            favoriteMusic: "ACO",
            occupation: "Crepe cafe"
        }
        case 2: return {
            date: "04/1999",
            name: "Noriko",
            fashionTip: "What the hell?",
            favoriteMusic: "Chara",
            occupation: "Band"
        }
        case 3: return {
            date: "10/1998",
            name: "Madoka",
            fashionTip: "Be careful!",
            favoriteMusic: "CASCADE",
            occupation: "University student"
        }
        case 4: return {
            date: "01/1998",
            name: "Kaori",
            fashionTip: "Gentle colors",
            favoriteMusic: "CASCADE",
            occupation: "Third-year high school student"
        }
        case 5: return {
            date: "03/2001",
            name: "Sunny",
            fashionTip: "I'm wearing the same as yesterday",
            favoriteMusic: "Judy and Mary",
            occupation: "Record store"
        }
        case 6: return {
            date: "02/1999",
            name: "Ayumi",
            fashionTip: "Red and White Christmas",
            favoriteMusic: "Sonic Youth",
            occupation: "First-year highschool student"
        }
    }
}

export const getFruitsMetadataFromLineIndex = (index: number) =>
    getFruitsMetadata(index + 1 as AuctionId)

export const getCurrentId = (): AuctionId => {
    const ids = window.location.href.split('/')
    const id = Number(ids[ids.length - 1])
    if (isNaN(id) || id === 0 || id > 6) return 1
    return id as AuctionId
}
