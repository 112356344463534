import { Mutex } from 'async-mutex'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { create } from 'zustand'
import { maxSupply, useParallelAuctionState } from './autoAuctionStore'

type WinnerStore = {
    lineWinners: O.Option<string>[],
    winningBid: O.Option<bigint>[],
    updateState: () => void
}

const updateStateMutex = new Mutex()

export const useWinnerState = create<WinnerStore>((set, get) => { return {
    lineWinners: [O.none, O.none, O.none, O.none, O.none, O.none],
    winningBid: [O.none, O.none, O.none, O.none, O.none, O.none],

    updateState: async () => await updateStateMutex.runExclusive(async () => {
        const finishedLines = pipe(
            useParallelAuctionState.getState().lines,
            O.map(pipe(A.map(l => O.isSome(l) && l.value.head > maxSupply)))
        )
        if (O.isNone(finishedLines)) return

        for (var i = 0; i < get().lineWinners.length; i++) {
            if (!finishedLines.value[i]) continue

            const currentBidEvent = pipe(
                await useParallelAuctionState.getState().getContractBidEventFor(i+1),
                O.map(winners => winners[winners.length - 1])
            )
            if (O.isNone(currentBidEvent)) continue

            // There can only be one winner, thus if its already set, we ignore any
            // possible mutations.
            const lineWinners = get().lineWinners
            if (O.isSome(lineWinners[i])) continue
            const winningBid = get().winningBid
            if (O.isSome(winningBid[i])) continue

            lineWinners[i] = O.of(currentBidEvent.value.bidder)
            winningBid[i] = O.of(currentBidEvent.value.price)

            set({ lineWinners, winningBid })
        }
    })
}})
