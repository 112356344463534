import React, { useEffect, useRef } from 'react'
import style from './TopNav.module.css'
import imgMasthead from './masthead.webp'

// BGM PLAYER ----------------------------- //
const BgmPlayer: React.FC = () => {
	const audioRef = useRef<HTMLAudioElement | null>(null)

	useEffect(() => {
		const handleFirstUserInteraction = () => {
			if (audioRef.current) {
				audioRef.current.play()
			}
		}

		if (audioRef.current) {
			audioRef.current.volume = 0.25 // Set volume to 50%
		}

		window.addEventListener('click', handleFirstUserInteraction)

		return () => {
			window.removeEventListener('click', handleFirstUserInteraction)
		}
	}, [])

	return <audio src="/bgm.mp3" ref={audioRef} style={{ visibility: 'hidden', height: 0 }} loop />
}

export const TopNav = () => {
	return (
		<header id={style['header']} className={style['host']}>
			<div id={style['brand-container']}>
				<img src={imgMasthead} alt="" id={style['masthead']} />
			</div>

			<div id={style['links-container']}>
				<a href="https://www.instagram.com/remiliaco" target="_blank" rel="noopener noreferrer">
					{' '}
				</a>
				<a href="https://www.instagram.com/fruitsmag/?hl=en" target="_blank" rel="noopener noreferrer">
					{' '}
				</a>
				<a href="https://store.remilia.org/" target="_blank" rel="noopener noreferrer">
					{' '}
				</a>
				<a href="https://docs.scatter.art/blog/fruits-x-remilia/" target="_blank" rel="noopener noreferrer">
					{' '}
				</a>
			</div>

			<BgmPlayer />
		</header>
	)
}
