import React from 'react'
import style from './AuctionPage.module.css'
import { AuctionGallery } from '../AuctionGallery/AuctionGallery'
import { TopNav } from '../TopNav/TopNav'
import { Toaster } from 'react-hot-toast'

export const AuctionPage: React.FC = () => {
	return (
		<>
			<main id={style['main-container']}>
				<TopNav />
				<AuctionGallery />
			</main>

			<Toaster
				position="top-center"
				containerStyle={{ top: 40 }}
				toastOptions={{
					className: '',
					style: {
						borderRadius: 'var(--sp-2)',
						padding: '16px',
						color: 'var(--clr-ink)',
						fontFamily: 'var(--font-display)',
						fontSize: '1.125rem'
					},
					iconTheme: {
						primary: 'var(--clr-ink)',
						secondary: 'var(--clr-background)'
					}
				}}
			/>
		</>
	)
}
