import { useEffect } from 'react'
import toast from 'react-hot-toast'
import * as E from 'fp-ts/Either'
import { useUserStore } from '../../../state/userStore'
import { useParallelAuctionState } from '../../../state/autoAuctionStore'

export const DappConnector = () => {
	const userConnected = useUserStore((state) => state.userConnected)
	const connection = useUserStore((state) => state.connectUser)
	const disconnection = useUserStore((state) => state.disconnectUser)
	const userAddr = useUserStore((state) => state.formattedUserAddress)
  
    // We call `rescueUser` once to try to get the currently connected user.
    const rescueUser = useUserStore((state) => state.rescueUser)
    const auctionData = useParallelAuctionState(s => s.auctionData)
    useEffect(rescueUser, [auctionData])

	const handleClick = async () => {
		if (!userConnected && E.isRight(await connection())) toast.success('Wallet connected')
		else {
			disconnection()
			toast('Wallet disconnected')
		}
	}

	const getWalletText = () => {
		if (userConnected) return `Disconnect ${userAddr}`
		else return 'Connect'
	}

	return (
		<button onClick={handleClick}>
			<span>{getWalletText()}</span>
		</button>
	)
}
